import {inject} from "aurelia-framework";
import {Client} from "../api/client";
import {DialogController} from 'aurelia-dialog';
import {FlashService} from "../flash/flash-service";
import moment from 'moment-timezone';
import * as _ from "lodash";
import {FormServiceFactory} from "../form/service/form-service-factory";
import {ConfigurationLoader} from "../form/loader/configuration-loader";

@inject(Client, DialogController, FlashService, ConfigurationLoader, FormServiceFactory)
export class AccommodationSearch {

    constructor(client, dialogController, flash, configurationLoader, formServiceFactory) {
        this.client = client;
        this.dialogController = dialogController;
        this.flash = flash;
        this.configurationLoader = configurationLoader;
        this.formServiceFactory = formServiceFactory;
    }

    onSelectionChangedCallback() {
        return (selectedItems) => {
            this.selectedItems = selectedItems;
        }
    }

    async activate(context) {
        console.log('context', context);
        this.data = context.data;
        this.order = context.order;
        this.participants = context.participants;

        this.endpoint = context.endpoint;
        this.selectModelId = context.selectModelId;

        let data = {
            participants: context.participants,
            order: context.order,
        };

        let config = await this.configurationLoader.get(this.endpoint, data);

        this.formService = this.formServiceFactory.getFormService(config, _.cloneDeep(data));
        this.formService.changeCallback = this.formValueChanged.bind(this);

        this._calculateParticipantCount();

        this.selectedItems = [];
    }

    formValueChanged(field, formService) {

        if (field.property === 'checkOutDate') {
            this._calculateParticipantCount();
        }

    }

    _calculateParticipantCount() {
        let adultCount = 0;
        let childCount = 0;

        if (this.participants.length === 0 && this.order.b2bParticipants) {
            this.adultCount = this.order.b2bParticipants;
            this.childCount = 0;
            return;
        }

        let value = this.formService.getValue();
        let children = [];
        for (let participant of this.participants) {

            let birthdate = participant?.customer?.birthdate;

            if (birthdate) {
                let years = moment(value?.checkOutDate).diff(moment(birthdate), 'years');

                if (years < 12) {
                    childCount++;
                    children.push(
                        {
                            'age': years
                        }
                    )
                } else {
                    adultCount++;
                }
            } else {
                adultCount++;
            }
        }

        this.childCount = childCount;
        this.adultCount = adultCount;
        this.children = children;
    }

    async search() {
        this.loading = true;

        try {
            let value = this.formService.getValue();

            let data = (await this.client.post(this.endpoint, Object.assign(
                {},
                value,
                {
                    adultCount: this.adultCount,
                    childrenCount: this.childCount,
                    children: this.children,
                }))).data;

            this.loading = false;

            //Todo error display

            this.searchId = data.searchId;
        } catch (err) {

            this.loading = false;
            this.searchId = null;

            let error = null;

            if (err && err.data) {
                if (err.data.localizedMessage) {
                    error = err.data.localizedMessage;
                } else if (err.data.message) {
                    error = err.data.message;
                }
            }

            this.flash.error(error || 'Ein unbekannter Fehler ist aufgetreten.');
        }
    }

    ok() {
        if (this.selectedItems.length !== 1) {
            this.flash.error('Bitte ein Suchergebnis auswählen');
            return;
        }

        this.dialogController.ok([{
            id: this.selectedItems[0].id,
            modelId: this.selectedItems[0].modelId
        }]);
    }

    isVisible(property) {
        let field = this.formService.getFieldByProperty(property);
        if (field) {
            return !field.hidden;
        }
        return false;
    }
}
