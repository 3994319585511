import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../api/client";
import {DialogController} from 'aurelia-dialog';
import {FlashService} from "../flash/flash-service";
import moment from 'moment-timezone';
import * as _ from "lodash";
import {FormServiceFactory} from "../form/service/form-service-factory";
import {ConfigurationLoader} from "../form/loader/configuration-loader";

@inject(Client, DialogController, FlashService, ConfigurationLoader, FormServiceFactory)
export class ServiceSearch {

    constructor(client, dialogController, flash, configurationLoader, formServiceFactory) {
        this.client = client;
        this.dialogController = dialogController;
        this.flash = flash;
        this.configurationLoader = configurationLoader;
        this.formServiceFactory = formServiceFactory;
    }

    onSelectionChangedCallback() {
        return (selectedItems) => {
            this.selectedItems = selectedItems;
        }
    }

    async activate(context) {
        console.log('context', context);
        this.data = context.data;
        this.order = context.order;
        this.participants = context.participants;

        this.endpoint = context.endpoint;
        this.selectModelId = context.selectModelId;

        let data = {
            participants: context.participants,
            order: context.order
        };

        let config = await this.configurationLoader.get(this.endpoint, data);

        this.formService = this.formServiceFactory.getFormService(config, _.cloneDeep(data));
        this.formService.changeCallback = this.formValueChanged.bind(this);

        this.selectedItems = [];
    }

    formValueChanged() {

    }

    async search() {
        this.loading = true;

        try {
            let value = this.formService.getValue();

            let data = (await this.client.post(this.endpoint, Object.assign(
                {},
                value,
                {
                    amount: this.participants.length
                }))).data;

            this.loading = false;

            //Todo error display

            this.searchId = data.searchId;
        } catch (err) {

            this.loading = false;
            this.searchId = null;

            let error = null;

            if (err && err.data) {
                if (err.data.localizedMessage) {
                    error = err.data.localizedMessage;
                } else if (err.data.message) {
                    error = err.data.message;
                }
            }

            this.flash.error(error || 'Ein unbekannter Fehler ist aufgetreten.');
        }
    }

    ok() {
        if (this.selectedItems.length !== 1) {
            this.flash.error('Bitte ein Suchergebnis auswählen');
            return;
        }

        this.dialogController.ok([{
            id: this.selectedItems[0].id,
            modelId: this.selectedItems[0].modelId
        }]);
    }
}
